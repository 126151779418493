import MuxPlayer from '@mux/mux-player-react';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/snickers/helpers';

interface VideoStyledProps {
  $fullWidth?: boolean;
}

export const MuxPlayerStyled = styled(MuxPlayer).attrs({ streamType: 'on-demand',
  loop: false,
  muted: false,
  autoPlay: false,
})`
  --dialog: none; /* Hide the error dialog */
  --media-object-fit: cover;

    align-self: center;
    object-fit: cover;
    width: 100%;



     @media screen and (min-width: ${breakpoints.mobileMax}) {
      grid-column: 2 /  span 10;
  }

  `;

export const VideoControls = styled.div``;

export const ControlButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  background: none;
  border: none;
  background-image: url('/svg/video-play.svg');
  background-repeat: no-repeat;
  background-size: contain;
  display: inline;
  transition: opacity 0.3s ease;


  @media screen and (min-width: ${breakpoints.mobileMax}) {
    width: 94px;
    height: 94px;
  }
`;

export const VideoWrapper = styled.div<{ $paused?: boolean }>`
  position: relative;
  text-align: center;
  margin-block: ${spacing.medium};
  max-inline-size: 1440px;
  margin-inline: auto;
  display: grid;
  place-items: stretch stretch;
  grid-auto-flow: row;
  grid-column-gap: 40px;
  padding-inline: 20px;
  grid-template-columns: 1fr;

  div > button  {
    opacity: ${({ $paused }) => ($paused ? '1' : '0')};
  }

  &:hover button {
    cursor: pointer;
    opacity: 0.8;
  }



  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }


`;
