import Image from 'next/image';

import {
  createDetailPageUrl,
  createProductImageSrc,
} from '@hultafors/snickers/helpers';
import { useFavorites, useFindCategory } from '@hultafors/snickers/hooks';
import { SnickersProduct } from '@hultafors/snickers/types';

import { Badge } from '../Badge/Badge';
import { Favorite } from '../Favorite/Favorite';
import { ProductColorsPreview } from '../ProductColorsPreview/ProductColorsPreview';
import { ProductDetails } from '../ProductDetails/ProductDetails';

import noImage from './no-image.png';
import {
  FavoriteContainer,
  ImageWrapper,
  ProductImage,
  ProductInfo,
  ProductLink,
  ProductPrice,
  ProductPriceExplanation,
  ProductStyled,
} from './Product.styled';

export interface ProductProps {
  product: SnickersProduct;
  badgeText?: string;
  rrpLabel: string;
  rrpIncludingVatLabel: string;
  categorySlug?: string;
  inFavorites?: boolean;
  isSmall?: boolean;
}

export const Product: React.FC<ProductProps> = ({
  product,
  badgeText,
  rrpLabel,
  rrpIncludingVatLabel,
  categorySlug,
  inFavorites,
  isSmall,
}) => {
  const { toggleFavoriteItem, isProductFavorite } = useFavorites();
  // TODO Dont do this in two places, see ProductListItem.tsx
  const { findCategory, findSubCategory } = useFindCategory();
  const { productId } = product;
  const subCategorySlug
    = product.categorySlug?.replace('-and-', '-') || findSubCategory(product);
  const parentSlug
    = product.parentSlug?.replace('-and-', '-')
    || categorySlug
    || findCategory(subCategorySlug);
  const href = createDetailPageUrl({
    productId,
    categorySlug: subCategorySlug,
    parentSlug,
  });

  const formattedProductName = product.name.replace(`${product?.category},`, '');

  return (
    <ProductStyled $inFavorites={inFavorites} $isSmall={isSmall}>
      <>
        <ProductImage $inFavorites={inFavorites} $isSmall={isSmall}>
          <ImageWrapper>
            <Image
              src={createProductImageSrc(product.image?.url, noImage)}
              fill
              style={{ objectFit: 'contain' }}
              sizes="(max-width: 768px) 50vw, 340px"
              alt={product.name}
            />
          </ImageWrapper>
          {product.isNew && badgeText && <Badge>{badgeText}</Badge>}
        </ProductImage>

        <ProductInfo>
          <ProductDetails>
            <span>{product.sku}</span>
            <span>{product.category}</span>
          </ProductDetails>
          <ProductLink href={href}>{formattedProductName}</ProductLink>

          {product.price
            ? (
              <ProductPrice>
                {`${product.price} ${product.currency}`}
                {' '}
                <ProductPriceExplanation>{rrpLabel}</ProductPriceExplanation>
              </ProductPrice>
              )
            : (
                product.priceWithVat && (
                  <ProductPrice>
                    {`${product.priceWithVat} ${product.currency}`}
                    {' '}
                    <ProductPriceExplanation>
                      {rrpIncludingVatLabel}
                    </ProductPriceExplanation>
                  </ProductPrice>
                )
              )}
          <ProductColorsPreview colors={product.colors || []} limit={6} />
        </ProductInfo>
        <FavoriteContainer>
          <Favorite
            toggleFavorite={toggleFavoriteItem}
            product={product}
            isFavorite={isProductFavorite(product.sku)}
            inFavorites={inFavorites}
          />
        </FavoriteContainer>
      </>
    </ProductStyled>
  );
};
