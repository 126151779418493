import { useMemo } from 'react';

import dynamic from 'next/dynamic';
import { BreadcrumbList, ListItem, WithContext } from 'schema-dts';

import { useGlobal } from '@hultafors/snickers/hooks';
import { BreadcrumbFragment, LinkFragment } from '@hultafors/snickers/types';

import { StyledBreadcrumbs, StyledLink } from './breadcrumbs.styled';

const MicroData = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.MicroData),
);
export interface BreadcrumbsProps extends BreadcrumbFragment {
  className?: string;
  style?: React.CSSProperties;
  slim?: boolean;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ id, links = [], className, style, slim }) => {
  const { settings } = useGlobal();
  const microdata: WithContext<BreadcrumbList> = useMemo(() => {
    const basePath = `/${settings.language?.urlPrefix || ''}`;
    const baseUrl = new URL(`https://${settings.hostname}${basePath}`);
    const itemListElement: ListItem[] = [{ id: '', url: '', title: settings.siteName }, ...links].map((link, index) => {
      const pathname = `${basePath}${link.url}`.replace(/\/\//g, '/');
      const url = new URL(pathname, baseUrl).toString();
      return {
        '@type': 'ListItem',
        'position': index + 1,
        'item':
            {
              '@id': url,
              'name': link.title || link.url,
            },
      };
    });
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement,
    };
  }, [links, settings]);

  const linkMapper = (link: LinkFragment) => {
    const key = `PageNavigation-${id}-${link.id}`;
    if (!link.url) {
      return null;
    }
    const href = `/${link.url}`.replace(/\/\//g, '/');
    return (
      <StyledLink key={key} href={href} title={link.title ?? link.url} $slim={slim}>
        {link.title || link.url}
      </StyledLink>
    );
  };
  if (!links?.length) {
    return null;
  }

  return (
    <>
      <StyledBreadcrumbs className={className} style={style} $slim={slim} data-test-id="Breadcrumbs">
        {links.map(linkMapper)}
      </StyledBreadcrumbs>
      <MicroData data={microdata} />

    </>
  );
};
