import { startTransition, useEffect, useRef, useState } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';
import { useScroll } from 'react-use';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { createUrl, recordTypes } from '@hultafors/snickers/helpers';
import { useGlobal } from '@hultafors/snickers/hooks';
import {
  GlobalFields,
  MenuItemFragment,
  MenuItemRecord,
} from '@hultafors/snickers/types';

import { DesktopMenuItem } from '../desktop-menu-item/desktop-menu-item';
import {
  DESKTOP_MENU_DRAWER_ID,
  DesktopMenuType,
} from '../DesktopMenu/DesktopMenu';
import { HeaderStoreItem } from '../HeaderStoreItem/HeaderStoreItem';
import { SlideInToggler } from '../SlideIn/SlideIn';

import LanguageSvg from './language.svg';
import LogoPng from './logo.png';
import MenuTogglerSvg from './menuToggler.svg';
import {
  ChangeCountry,
  DesktopNav,
  Logo,
  MenuActions,
  MenuToggler,
  MobileNav,
  NavigationStyled,
  Partner,
  Search,
  TopActions,
  TopActionsLabel,
  TopMenu,
} from './Navigation.styled';
import PartnerSvg from './partner.svg';
import SearchSvg from './search.svg';

export interface NavigationProps {
  disabled?: boolean;
  isTransparent?: boolean;
  isOpen?: boolean;
  productMenu?: any;
  toggleMenu: SlideInToggler;
  toggleDesktopMenu: SlideInToggler<DesktopMenuType>;
  toggleFavoritesMenu: SlideInToggler;
  openDesktopMenuType?: DesktopMenuType | null;
  menuData: GlobalFields['allMenuItems'];
}

export const Navigation: React.FC<NavigationProps> = ({
  toggleDesktopMenu,
  toggleMenu,
  toggleFavoritesMenu,
  isTransparent,
  isOpen,
  openDesktopMenuType,
  menuData,
}) => {
  const [scrolledDown, setScrolledDown] = useState<boolean>(false);
  const comp = useRef(null);
  const { global, allMenuItems } = useGlobal();
  const scrollRef = useRef(null);
  const { y } = useScroll(scrollRef);
  const { asPath } = useRouter();
  const transparent = isTransparent && !scrolledDown && !isOpen;
  const { toggle, activeMarket } = useMarketPicker();

  function renderLanguageIcon() {
    let iconUrl;

    if (!activeMarket || (!activeMarket?.flag && !activeMarket?.symbol?.url)) {
      return <LanguageSvg focusable={false} aria-hidden={true} height={24} />;
    }
    if (activeMarket?.flag && !activeMarket?.symbol?.url) {
      iconUrl = `https://flagcdn.com/${activeMarket.flag}.svg`;
    }
    if (activeMarket?.symbol?.url) {
      iconUrl = activeMarket.symbol.url;
    }
    return (
      <img
        src={iconUrl ?? ''}
        width={24}
        height={24}
        alt={activeMarket.name ?? ''}
      />
    );
  }

  useEffect(() => {
    if (comp.current && isTransparent) {
      startTransition(() => {
        setScrolledDown(y > 100);
      });
    }
  }, [y]);

  const getMenuItemType = (
    menuItem: Partial<MenuItemRecord>,
  ): DesktopMenuType | null =>
    menuItem.children?.[0]?.pageLink?.__typename ? 'CategoryPageRecord' : null;

  const menuTogglerOnClick = () => toggleMenu();
  const favoritesTogglerOnClick = () => toggleFavoritesMenu();
  function onClickSearch(): void {
    toggleDesktopMenu('search');
  }

  function getHref(item: MenuItemFragment): string {
    if (item.pageLink?.__typename === 'CategoryPageRecord') {
      return `/products/${item.pageLink?.slug}`;
    }
    if (item.newUrl) {
      return createUrl(item.newUrl);
    }
    return '';
  }

  function getActive(item: MenuItemFragment): boolean {
    if (!asPath || asPath === '/') {
      return false;
    }
    if (item.pageLink?.__typename === 'CategoryPageRecord') {
      return asPath.startsWith('/products');
    }
    if (item.newUrl) {
      return asPath.startsWith(createUrl(item.newUrl));
    }
    return false;
  }

  function desktopMenuMapper(item: MenuItemFragment) {
    const menuType = getMenuItemType(item as MenuItemRecord);
    const key = `DesktopMenuItem-${item.id}`;
    const href = getHref(item);
    const active = getActive(item);

    if (item.children?.length) {
      const onClick: React.MouseEventHandler = (event) => {
        event.preventDefault();
        toggleDesktopMenu(menuType);
      };

      return (
        <DesktopMenuItem
          key={key}
          onClick={onClick}
          active={active}
          href={href}
          className={menuType === recordTypes.CATEGORY ? 'productmenu' : ''}
          data-slidein-ignore={DESKTOP_MENU_DRAWER_ID}
        >
          {item.title}
        </DesktopMenuItem>
      );
    }
    if (item) {
      return (
        <DesktopMenuItem key={key} href={href} active={active}>
          {item.title}
        </DesktopMenuItem>
      );
    }
    return null;
  }

  return (
    <NavigationStyled
      $transparent={isTransparent && !scrolledDown && !isOpen}
      ref={comp}
    >
      <MobileNav>
        <MenuToggler
          onClick={menuTogglerOnClick}
          aria-label={global?.menuLabel || ''}
        >
          <MenuTogglerSvg
            width={20}
            height={20}
            aria-hidden={true}
            focusable={false}
          />
        </MenuToggler>

        <Logo href="/">
          <Image
            src={LogoPng}
            width={75}
            height={40}
            alt="Snickers Workwear"
            priority={true}
            sizes="75px"
          />
        </Logo>

        <MenuActions>
          <ChangeCountry onClick={() => toggle(true)}>
            {renderLanguageIcon()}
            <TopActionsLabel $transparent={transparent}></TopActionsLabel>
          </ChangeCountry>
          <HeaderStoreItem isFavorite onClick={favoritesTogglerOnClick} />
        </MenuActions>
      </MobileNav>

      <DesktopNav>
        <Logo href="/">
          <Image
            src={LogoPng}
            width={75}
            height={40}
            alt="Snickers Workwear"
            priority={true}
            sizes="75px"
          />
        </Logo>
        <TopMenu>{allMenuItems?.map(desktopMenuMapper)}</TopMenu>
        <TopActions $transparent={transparent}>
          <ChangeCountry onClick={() => toggle(true)}>
            {renderLanguageIcon()}
            <TopActionsLabel $transparent={transparent}>
              {global?.changeLanguage || ''}
            </TopActionsLabel>
          </ChangeCountry>
          <Search
            onClick={onClickSearch}
            data-slidein-ignore={DESKTOP_MENU_DRAWER_ID}
            aria-label={global?.search || 'Search'}
          >
            <SearchSvg focusable={false} aria-hidden={true} height={24} />
            <TopActionsLabel $transparent={transparent}>
              {global?.search || ''}
            </TopActionsLabel>
          </Search>
          <HeaderStoreItem
            isFavorite
            onClick={favoritesTogglerOnClick}
            isTransparent={isTransparent && !scrolledDown && !isOpen}
            iconText={global?.favoritesHeader || ''}
          />
          {global?.partnerPortalUrl && (
            <Partner
              href={global?.partnerPortalUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PartnerSvg focusable={false} aria-hidden={true} height={24} />
              <TopActionsLabel>Partner</TopActionsLabel>
            </Partner>
          )}
        </TopActions>
      </DesktopNav>
    </NavigationStyled>
  );
};
